import { destroy, post, put } from '@/application/api/implementations/app';

export const create = (orderId, values) => post(`order/${orderId}/custom-order-line`, values);

export const addFile = (customOrderId, values) => post(`custom-order-line/${customOrderId}/file`, values);

export const update = (values) => put(`custom-order-line/${values.id}`, values);

export const remove = (customOrderId) => destroy(`custom-order-line/${customOrderId}`);

export const removeFile = (customOrderId, fileId) => destroy(`custom-order-line/${customOrderId}/file/${fileId}`);
