<template>
  <v-timeline-item hide-dot :right="isSender" :left="!isSender">
    <v-card color="background" class="elevation-0 rounded-xl" flat>
      <v-card-title class="pt-2 pb-0">
        <v-row no-gutters>
          <div class="text--disabled body-2 font-italic">{{ comment.userName }}</div>
          <VSpacer/>
          <div class="text--primary body-1 font-weight-medium">{{ comment.time }}</div>
        </v-row>
      </v-card-title>
      <v-card-text class="text--primary body-2">
        {{ comment.comment }}
      </v-card-text>
    </v-card>
    <template #opposite>
      <v-chip v-if="isDateVisible">{{ comment.date }}</v-chip>
    </template>
  </v-timeline-item>
</template>

<script>
import OrderComment from '@/application/models/Comment.js';

export default {
  name: 'OrderCommentTimelineItem',
  props: {
    comment: {
      type: OrderComment,
      required: true,
    },
    isDateVisible: {
      type: Boolean,
    },
    isSender: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
