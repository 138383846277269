<template>
  <div class="d-flex box--total mt-2">
    <div class="d-inline-flex align-self-center pl-6 pr-8">
      <div class="mt-1 mr-4 font-weight-medium text-h3">{{ $t('shoppingCart.total') }}:</div>
      <i18n-n :tag="false"
              :value="totalAmount"
              :format="{ key: 'currency' }"
              class="d-inline-flex">
        <template #currency="{currency}">
          <div class="text-h1 font-weight-medium">{{ currency }}</div>
        </template>
        <template #group="{group}">
          <div class="text-h1 font-weight-medium">{{ group }}</div>
        </template>
        <template #integer="{integer}">
          <div class="text-h1 font-weight-medium">{{ integer }}</div>
        </template>
        <template #decimal="{decimal}">
          <div class="text-h1 font-weight-medium">{{ decimal }}</div>
        </template>
        <template #fraction="{fraction}">
          <div class="text-h3 font-weight-medium mt-1">{{ fraction }}</div>
        </template>
      </i18n-n>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayTotalAmount',
  props: {
    value: {
      type: Number,
    },
  },
  data: () => ({
    totalAmount: 0,
  }),
  created() {
    this.totalAmount = this.value;
  },
  watch: {
    value() {
      this.totalAmount = this.value;
    },
  },
};
</script>

<style scoped>

</style>
