import { render, staticRenderFns } from "./OrderCommentsSideBar.vue?vue&type=template&id=80a59ef6&scoped=true&"
import script from "./OrderCommentsSideBar.vue?vue&type=script&lang=js&"
export * from "./OrderCommentsSideBar.vue?vue&type=script&lang=js&"
import style0 from "./OrderCommentsSideBar.vue?vue&type=style&index=0&id=80a59ef6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80a59ef6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VTimeline})
