<template>
  <k-sidebar v-bind="$attrs" v-on="$listeners" fixed right temporary width="600" :with-save-button="false">
    <v-timeline class="comments-timeline">
      <OrderCommentTimelineItem v-for="(comment, index) in comments"
                                :comment="comment"
                                :key="comment.id"
                                :is-sender="getCurrentUserId === comment.userId"
                                :is-date-visible="hasDateChip(index)"/>
    </v-timeline>
  </k-sidebar>
</template>

<script>
import KSidebar from '@/components/layout/KSidebar.vue';
import OrderCommentTimelineItem from '@/modules/orders/components/OrderCommentTimelineItem.vue';
import { comments } from '@/modules/orders/api/comment.js';
import OrderComment from '@/application/models/Comment.js';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderCommentsSideBar',
  components: {
    OrderCommentTimelineItem,
    KSidebar,
  },
  created() {
    this.getComments();
  },
  data() {
    return {
      comments: [],
    };
  },
  computed: {
    ...mapGetters('authorisation', ['getCurrentUserId']),
  },
  methods: {
    async getComments() {
      const response = await comments(this.$route.params.orderId);
      this.comments = response.data.data.map(comment => new OrderComment().mapResponse(comment));
    },
    hasDateChip(index) {
      return index === 0 || this.comments[index - 1].date !== this.comments[index].date;
    },
  },
};
</script>

<style scoped>
.comments-timeline {
  min-height: 97vh;
}
</style>
