<template>
  <v-dialog :value="delayedValue"
            transition="dialog-top-transition"
            max-width="1000"
            scrollable
            no-click-animation>
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-actions>
          <VSpacer/>
          <v-btn @click="closeDialog" color="secondary" outlined tile :loading="isRequestLoading">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn @click="handleSave" color="secondary" tile :loading="isRequestLoading">
            {{ $t(`actions.${isUpdateForm ? 'edit' : 'save'}`) }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <k-field-group language-prefix="orders.fields">
            <v-row class="ma-0">
              <v-col cols="12">
                <KSubtitle class="pa-0">
                  {{ $t(`orders.fields.${isUpdateForm ? 'editManualOrderLine' : 'addManualOrderLine'}`) }}
                </KSubtitle>
                <VDivider/>
              </v-col>
              <v-col cols="12" md="4">
                <KTextField field="title" v-model="values.title" required/>
                <KTextField field="description" v-model="values.description"/>
                <KTextField field="price" v-model="values.price" required/>
                <KTextField field="quantity" v-model="values.quantity" required/>
                <KTextField field="code" v-model="values.itemNumber"/>
                <DisplayTotalAmount v-model="totalAmount"/>
              </v-col>
              <v-col cols="12" md="4">
                <!-- On change, add files with post request-->
                <KFileField field="files" multiple v-model="values.newFiles"/>
              </v-col>
              <v-col cols="12" md="4">
                <span>{{ $t('orders.fields.uploadedFiles') }}</span>
                <v-data-table :items="values.files" hide-default-footer>
                  <template #item="{item}">
                    <tr v-show="!values.deletedFiles.includes(item.id)">
                      <td>
                        {{ item.name }} <br/>
                        <span class="font-italic">{{ $t('orders.fields.createdAt') }}</span>
                        {{ createdAtFile(item) }}
                      </td>
                      <td style="max-width: 40px;" class="align-end">
                        <v-btn color="text--secondary"
                               icon
                               @click="removeFile(item)">
                          <v-icon>$delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </k-field-group>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import eventBus from '@/application/eventBus.js';
import { addFile } from '@/modules/orders/api/customOrderLine.js';
import DisplayTotalAmount from '@/modules/orders/components/DisplayTotalAmount.vue';
import dayjs from '@/plugins/dayjs.js';

export default {
  name: 'ManualOrderLineForm',
  components: {
    DisplayTotalAmount,
    KFileField,
    KSubtitle,
    KFieldGroup,
    KTextField,
  },
  props: {
    values: {
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Function,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    delayedValue: false,
    valid: false,
    isRequestLoading: false,
    totalAmount: 0,
  }),
  watch: {
    value(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.delayedValue = true;
        return;
      }
      this.delayedValue = false;
    },
    'values.price'() {
      this.calculateTotalAmount();
    },
    'values.quantity'() {
      this.calculateTotalAmount();
    },
  },
  methods: {
    calculateTotalAmount() {
      this.totalAmount = parseInt(this.values?.price || 0) * parseInt(this.values?.quantity || 0);
    },
    async handleSave() {
      this.$refs.form.validate();
      if (!this.valid || this.isRequestLoading) return;
      this.isRequestLoading = true;
      try {
        if (this.isUpdateForm) {
          for (let x = 0; x < this.values.newFiles.length; x++) {
            await addFile(this.values.id, {
              file: this.values.newFiles[x],
            });
          }
          this.values.newFiles = [];
        }

        await this.request();
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t(`actions.${this.isUpdateForm ? 'resourceUpdated' : 'resourceCreated'}`, { resource: this.$t(`orders.fields.manualOrderLine`) }),
        });
        this.closeDialog();
        this.$emit('change');
      } catch (error) {
        if (error.response && error.response.status) {
          this.validate();
          return;
        }
        throw error;
      } finally {
        this.isRequestLoading = false;
      }
    },
    closeDialog() {
      this.$emit('input', false);
    },
    removeFile(file) {
      this.values.deletedFiles.push(file.id);
    },
    createdAtFile(file) {
      return dayjs(file.createdAt).format('DD-MMM-YY');
    },
  },
};
</script>
