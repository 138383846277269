var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex box--total mt-2"},[_c('div',{staticClass:"d-inline-flex align-self-center pl-6 pr-8"},[_c('div',{staticClass:"mt-1 mr-4 font-weight-medium text-h3"},[_vm._v(_vm._s(_vm.$t('shoppingCart.total'))+":")]),_c('i18n-n',{staticClass:"d-inline-flex",attrs:{"tag":false,"value":_vm.totalAmount,"format":{ key: 'currency' }},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var currency = ref.currency;
return [_c('div',{staticClass:"text-h1 font-weight-medium"},[_vm._v(_vm._s(currency))])]}},{key:"group",fn:function(ref){
var group = ref.group;
return [_c('div',{staticClass:"text-h1 font-weight-medium"},[_vm._v(_vm._s(group))])]}},{key:"integer",fn:function(ref){
var integer = ref.integer;
return [_c('div',{staticClass:"text-h1 font-weight-medium"},[_vm._v(_vm._s(integer))])]}},{key:"decimal",fn:function(ref){
var decimal = ref.decimal;
return [_c('div',{staticClass:"text-h1 font-weight-medium"},[_vm._v(_vm._s(decimal))])]}},{key:"fraction",fn:function(ref){
var fraction = ref.fraction;
return [_c('div',{staticClass:"text-h3 font-weight-medium mt-1"},[_vm._v(_vm._s(fraction))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }