import Model from './model';
import dayjs from '@/plugins/dayjs.js';

export default class OrderComment extends Model {
  id = 0;
  userName = '';
  comment = '';
  date = '';
  time = '';
  userId = 0;

  mapResponse(data) {
    return super.mapResponse({
      ...data,
      date: dayjs(data.createdAt).format('DD-MM-YYYY'),
      time: dayjs(data.createdAt).format('HH:mm'),
    });
  }
}
