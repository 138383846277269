<template>
  <div :class="this.grid" class="k-field-wrapper">
    <v-checkbox
        v-bind="fieldProps"
        v-on="$listeners"
        :class="checkboxClass"
        :label="translatedLabel"
        :rules="computedRules">
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name"/>
      </template>
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"/>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KCheckbox',
  mixins: [Field],
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
    },
    checkboxClass: {
      type: String,
    },
  },
};
</script>
