<template>
  <v-col cols="12">
    <v-row no-gutters>
      <h2 v-bind="$attrs"
          v-on="$listeners"
          :class="titleClass"
          class="text-h3 secondary--text text-uppercase font-weight-bold text--darken-4">
        <slot/>
      </h2>
    </v-row>
    <v-row no-gutters>
      <div v-bind="$attrs" v-on="$listeners" :class="subtitleClass" class="subtitle-1">
        <slot name="subtitle"></slot>
      </div>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'KSubtitle',
  inheritAttrs: false,
  props: {
    titleClass: { type: String },
    subtitleClass: { type: String },
  },
};
</script>
