import Model from './model';

export class OrderLine extends Model {
  id = 0;
  productId = 0;
  image = '';
  sku = '';
  name = '';
  description = '';
  price = 0;
  quantity = 0;
  lineTotal = 0;
  localLineTotal = 0;
  addOns = []

  mapForRequest() {
    return { ...this }
  }

  mapResponse(data) {
    data.price = (data.price /100).toFixed(2);
    data.lineTotal = (data.lineTotal /100).toFixed(2);
    data.localLineTotal = (data.localLineTotal /100).toFixed(2);
    data.addOns = data['add-ons'];
    data.addOns.totalPrice = data.addOns.totalPrice / 100;
    data.addOns.products.map(product => {
      return {
        ...product,
        price: (product.price / 100).toFixed(2),
      };
    });
    return super.mapResponse(data);
  }
}

export default class ShoppingCart extends Model {
  id = 0;
  amount = 0;
  orderLines = [];

  mapForRequest() {
    return {
      amount: this.amount,
      orderLines: this.orderLines.map(item => item.mapForRequest())
    };
  }

  mapResponse(data) {
    data = super.mapResponse(data);
    data.orderLines = data.orderLines.map(item => new OrderLine().mapResponse(item))
    return data
  }
}
